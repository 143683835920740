import React from 'react';
import styles from './BlurbModule.module.scss';
import { Maybe, PbsKidsTheme } from '@/types/pbskids-graph';
import ThemedModule from '@/components/global/ThemedModule';

interface Props {
  blurbCopy?: Maybe<string>
  theme?: Maybe<PbsKidsTheme> | Maybe<Array<Maybe<PbsKidsTheme>>>
}

const BlurbModule: React.FC<Props> = ({
  blurbCopy,
  theme,
}) => {
  return blurbCopy && <ThemedModule theme={theme} backgroundMode='pattern'>
    <section
      className={ styles.blurbModule }
    >
      <h2
        className={ styles.copy }
      >{ blurbCopy }
      </h2>
    </section>
  </ThemedModule>;
};

export default BlurbModule;
