import { ReactElement } from 'react';
import { defaultElement } from '@/components/modules/BodyContentModule';
import BlurbModule from '@/components/modules/BlurbModule';
import FreeFormModule from '@/components/modules/FreeFormModule';
import HeaderModule from '@/components/modules/HeaderModule';
import PropertiesNavigationBanner from '@/components/modules/PropertiesNavigationBanner';
import { BodyContentModulesBodyCopy, BodyContentModulesFreeformGraphicAndText, BodyContentModulesPropertiesNavigationBanner, BodyContentModulesTextBlurb, PropertiesNavBannerCard } from '@/types/pbskids-graph';
import { ResolvedPageThemes } from '@/utils/theming-system';

export type BodyContentCommonModuleType =
  BodyContentModulesBodyCopy |
  BodyContentModulesPropertiesNavigationBanner |
  BodyContentModulesTextBlurb |
  BodyContentModulesFreeformGraphicAndText;

export default function BodyContentCommonModule({ module, themeData }: {
  module: BodyContentCommonModuleType,
  themeData?: ResolvedPageThemes,
}): ReactElement {
  const useBgOptions = [ '#ffffff', null, undefined ];
  const usePropNavBackgrounds = useBgOptions.includes(themeData?.primaryTheme.featureBackgroundColor?.toLowerCase());

  switch (module.__typename) {
    case 'BodyContentModulesBodyCopy':
      return <HeaderModule
        heading={ module.heading }
        body={ module.richText }
      ></HeaderModule>;

    case 'BodyContentModulesTextBlurb':
      return <BlurbModule
        blurbCopy={ module.text }
        theme={ module.theme }
      ></BlurbModule>;

    case 'BodyContentModulesPropertiesNavigationBanner':
      return <PropertiesNavigationBanner
        cards={ module.cards as PropertiesNavBannerCard[] }
        cardBackgrounds={ usePropNavBackgrounds }
        listId={ module.id }
      ></PropertiesNavigationBanner>;

    case 'BodyContentModulesFreeformGraphicAndText':
      return <FreeFormModule
        module={ module }
      ></FreeFormModule>;

    default:
      return defaultElement;
  }
}
