import { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import Logger from '@/utils/logger/base';
const logger = new Logger({ caller: 'components.base.LazyLoader', levels: process.env.DEBUG_MODE === 'true' ? 'verbose' : 'off' });

interface Props {
  children: ReactNode,
  identifier: string,
  onLoaded?: (el: HTMLElement|null) => void,
}

export default function LazyLoader({ children, identifier, onLoaded }: Props) {
  const elementRef: RefObject<HTMLDivElement> = useRef(null);
  const trackableSpanRef: RefObject<HTMLDivElement> = useRef(null);
  const [ isVisible, setIsVisible ] = useState(false);
  const [ isPending, setIsPending ] = useState(false);
  const revealDelay = 500; // in milliseconds

  useEffect(() => {
    if (isVisible || isPending) {
      logger.log(`Is Visible/Pending: Do not add IntersectionObserver, ${identifier}`);
      return;
    }

    // Ensure the browser supports the Intersection Observer API
    if (!('IntersectionObserver' in window)) {
      setIsVisible(true);
      return;
    }

    const reveal = (elem: HTMLDivElement|null) => {
      logger.log(`Set isVisible = true, ${identifier}`);
      setIsVisible(true);
      setIsPending(false);
      onLoaded?.(elem);
    };

    const observer = new IntersectionObserver(
      ([ entry ]) => {
        if (entry.isIntersecting) {
          logger.log(`Set isPending = true, ${identifier}`);
          setIsPending(true);
          setTimeout(reveal.bind(null, trackableSpanRef.current), revealDelay);
        }
      },
      {
        root: null, // viewport
        rootMargin: '0px', // no margin
        threshold: 0.9, // percentage of target visible, 0.0 - 1.0
      },
    );

    if (elementRef.current) {
      logger.log(`Observing: ${identifier}`, elementRef.current);
      observer.observe(elementRef.current);
    }

    // Clean up the observer
    return () => {
      logger.log(`Unmounting: ${identifier}`, elementRef);
      observer.disconnect();
    };
  }, [ identifier, isPending, isVisible, onLoaded ]);

  return <>
    {/* This span is necessary for analytics tracking, while avoiding
      the creation of a wrapper element on { children }, which causes
      layout issues as the carousel modules start to load. */}
    <span data-ga-track-next-sibling style={{ display: 'none' }} ref={ trackableSpanRef } />
    { isVisible && children }

    {!isVisible && <div
      ref={ elementRef }
      className={ isPending ? 'loader-bug' : '' }
      style={{
        height: '130px',
        backgroundSize: '230px',
      }}
    />}
  </>;
}
