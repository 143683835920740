import { ReactElement } from 'react';
import { defaultElement } from '@/components/modules/BodyContentModule';
import { BodyContentCommonModuleType } from '@/components/modules/BodyContentCommonModule';
import CarouselModule from '@/components/modules/CarouselModule';
import GridLowerModule from '@/components/modules/GridLowerModule';
import LiveModule from '@/components/modules/LiveModule';
import SingleVideoModule from '@/components/modules/SingleVideoModule';
import { BodyContentModulesLiveTv, BodyContentModulesVideo, BodyContentModulesVideoContentCards, BodyContentModulesVideoPlaylist, PbsKidsVideo, VideoContentCard } from '@/types/pbskids-graph';
import RowLowerModule from '@/components/modules/RowLowerModule';
import { filterCards, filterVideos } from '@/utils/video-cards/filters';

export type BodyContentVideoModuleType =
  BodyContentCommonModuleType |
  BodyContentModulesLiveTv |
  BodyContentModulesVideo |
  BodyContentModulesVideoPlaylist |
  BodyContentModulesVideoContentCards;

export default function BodyContentVideoModule({ module, referringPage }: {
  module: BodyContentVideoModuleType,
  referringPage?: string,
}): ReactElement {
  switch (module.__typename) {
    case 'BodyContentModulesLiveTv':
      return <LiveModule
        moduleId={module.id}
        targetProperty={ module.properties && module.properties[0] ? module.properties[0] : null }
      ></LiveModule>;

    case 'BodyContentModulesVideo':
      if (!module.video || !module.video[0]) {
        return defaultElement;
      }

      return <SingleVideoModule
        item={module.video[0]}
        listId={module.id}
      ></SingleVideoModule>;

    case 'BodyContentModulesVideoPlaylist': {
      if (!module.collection || !module.collection[0]) {
        return defaultElement;
      }

      // If playlist belongs to a only a single property, then don't display the video's show title.
      const displayShowTitle = !(module.collection[0].properties?.length === 1);
      const videos = module.collection[0].entries as PbsKidsVideo[];
      const moduleData = {
        cardStyle: module.cardStyle as string,
        context: {
          id: module.collection[0].id,
          loops: true,
          referringPage,
          slug: module.collection[0].slug,
          title: module.collection[0].title,
          displayShowTitle: displayShowTitle,
        },
        items: videos.filter(filterVideos(module.cardStyle as string)),
        title: module.heading as string,
      };

      if (moduleData.items.length) {
        if (module.layout === 'grid') {
          return <GridLowerModule
            title={ moduleData.title }
            items={ moduleData.items }
            cardStyle={ moduleData.cardStyle }
            context={ moduleData.context }
            listId={ module.id }
          ></GridLowerModule>;
        }

        if (module.layout === 'row') {
          return <RowLowerModule
            title={ moduleData.title }
            items={ moduleData.items }
            cardStyle={ moduleData.cardStyle }
            context={ moduleData.context }
            listId={ module.id }
          ></RowLowerModule>;
        }

        if (module.layout === 'carousel') {
          return <CarouselModule
            items={ moduleData.items }
            cardStyle='mezzanine'
            context={ moduleData.context }
            listId={ module.id }
          ></CarouselModule>;
        }
      }

      return defaultElement;
    }

    case 'BodyContentModulesVideoContentCards': {
      if ( !module.cards ) {
        return defaultElement;
      }

      const cards = module.cards as VideoContentCard[];
      const moduleData = {
        cardStyle: module.cardStyle as string,
        linkItems: cards.filter(filterCards(module.cardStyle as string)),
        listId: module.id,
        title: module.heading as string,
      };

      if (moduleData.linkItems.length) {
        if (module.layout === 'grid') {
          return <GridLowerModule
            title={ moduleData.title }
            linkItems={ moduleData.linkItems }
            cardStyle={ moduleData.cardStyle }
            listId={ moduleData.listId }
          ></GridLowerModule>;
        }

        if (module.layout === 'row') {
          return <RowLowerModule
            title={ moduleData.title }
            linkItems={ moduleData.linkItems }
            cardStyle={ moduleData.cardStyle }
            listId={ moduleData.listId }
          ></RowLowerModule>;
        }

        if (module.layout === 'carousel') {
          return <CarouselModule
            linkItems={ moduleData.linkItems }
            cardStyle='mezzanine'
            listId={ moduleData.listId }
          ></CarouselModule>;
        }
      }

      return defaultElement;
    }

    default:
      return defaultElement;
  }
}

