import envData from '../.pre-build/env.json';
import Logger from './logger/base';

const { deployedEnvName } = envData;

const logger = new Logger({ caller: 'utils.page' });

export const isPrivatePage = (uri: string): boolean => {
  // There are no private pages on debug environments
  if (process.env.DEBUG_MODE === 'true') return false;

  // __home__ SHOULD NEVER BE CONSIDERED PRIVATE.
  if (uri === '__home__') return false;

  // If the page URI starts with an underscore, then it's a private page
  if (/^_/.test(uri)) {
    if (deployedEnvName === 'prod') {
      // If we see blocked private pages in prod, that may indicate a problem we
      // want to know about, so the `warn` log level will be sent to Sentry.
      logger.warn(`Blocked access to private page \`${uri}\``);
    } else {
      logger.debug(`Blocked access to private page \`${uri}\` Might want to set \`DEBUG_MODE=true\`?`);
    }
    return true;
  }

  // Default state is not private.
  return false;
};
