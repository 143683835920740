import React from 'react';
import { useContext } from 'react';
import { PbsKidsProperty, PropertiesNavBannerCard } from '@/types/pbskids-graph';
import { EmblaOptionsType } from 'embla-carousel';
import emblaCarouselClassNames from 'embla-carousel-class-names';
import { usePrevNextButtons } from '@/components/base/CarouselNavButtons';
import Link from 'next/link';
import CharacterCard from '@/components/base/CharacterCard';
import ArrowButtonLeft from '@/components/base/ArrowButton/ArrowButtonLeft';
import ArrowButtonRight from '@/components/base/ArrowButton/ArrowButtonRight';
import styles from './PropertiesNavigationBanner.module.scss';
import stackStyles from '@/components/base/PageSectionStack/PageSectionStack.module.scss';
import LanguageLocalizer from '@/utils/language-localization';
import Logger from '@/utils/logger';
import { useAccessibleCarousel } from '@/components/modules/CarouselModule/useAccessibleCarousel';

const logger = new Logger({ caller: 'components.modules.PropertiesNavigationBanner' });

interface Props {
  cards: PropertiesNavBannerCard[],
  cardBackgrounds?: boolean,
  listId?: string,
}

const validateCards = (cards: PropertiesNavBannerCard[] | null | undefined) => {
  const checkedCards: PropertiesNavBannerCard[] = [];

  if (cards && cards.length) {
    cards.forEach((card) => {
      if (card && card.property?.[0]?.navigationCardLogo) {
        const hasTargetUrl = card?.targetUrl || card?.target?.[0]?.uri;
        if (hasTargetUrl) {
          checkedCards.push(card);
        } else {
          logger.warn(`PropertiesNavigationBanner: Card for ${card?.property?.[0]?.title} is missing a target URL.`);
        }
      }
    });
  }

  return checkedCards;
};

const PropertiesNavigationBanner: React.FC<Props> = ({
  cards,
  cardBackgrounds = true,
  listId,
}) => {
  const { __t } = new LanguageLocalizer( useContext, 'components/modules/PropertiesNavigationBanner' );

  const validCards = validateCards(cards);

  const cardOptions = (): EmblaOptionsType => {
    return {
      align: 'start',
      containScroll: 'keepSnaps',
      loop: true,
      dragFree: true,
      inViewThreshold: 1,
    };
  };

  const emblaOptions = cardOptions();

  // Passing empty strings to these class names, because previously we saw a fatal error if there was an empty set of cards passed in:
  // `TypeError: slideIndexes is undefined`, from embla-carousel-class-names.
  const [ emblaRef, emblaApi ] = useAccessibleCarousel(emblaOptions, [ emblaCarouselClassNames({ inView: `${styles.slideInView}`, snapped: '', dragging: '' }) ]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (!!validCards.length) &&
    <nav
      aria-labelledby='properties-nav-title'
      className={`${styles.embla} ${stackStyles.isPropertyNav}`}
      data-carousel-wrapper='true'
      id='properties-nav-banner'
    >
      <h2
        id='properties-nav-title'
        className='sr-only'>
        {__t('propertiesNavTitle')}
      </h2>
      <div
        className={`${styles.characterList} ${styles.emblaViewport}`}
        ref={ emblaRef }
      >
        <ul
          data-ga-view-list-module={listId}
          data-ga-has-thumb-bg={ cardBackgrounds ? true : undefined }
          className={`${styles.emblaContainer}`}>
          { validCards.map( (card) => {
            const series = card.property?.[0] as PbsKidsProperty;

            const cardBackground = cardBackgrounds ? series.characterImageBackground?.[0]?.url : '';

            const croppedBackground = () => {
              const itsUrl = series.characterImageBackground?.[0]?.its_url;
              if (cardBackgrounds && itsUrl) {
                const itsQueryString = '?resize=x90&crop=90x90';
                return itsUrl.concat(itsQueryString);
              }
            };

            return (
              ( series.slug &&
                series.characterImage?.[0]?.url &&
                series.navigationCardLogo?.[0]?.url &&
                (card.target?.[0]?.uri || card.targetUrl)
              ) &&

              <li className={styles.emblaSlide} key={ series.slug } >
                <Link
                  href={ card.targetUrl || card.target?.[0]?.uri || ''}
                  aria-label={ series?.title || series?.slug || ''}
                >
                  <CharacterCard
                    className={ styles.characterCard }
                    characterImage={ series.characterImage[0].url }
                    navigationCardLogo={ series.navigationCardLogo[0].url }
                    characterImageBackground={ cardBackground || undefined }
                    characterImageBackgroundCrop={ croppedBackground() || undefined }
                    backgroundClassName={ styles.backgroundContainer }
                    characterImageClassName={ styles.character }
                    navigationCardLogoClassName={ styles.characterLogo }
                  />
                </Link>
              </li>
            );
          }) }
        </ul>
      </div>
      <ArrowButtonLeft
        onClick={onPrevButtonClick}
        disabled={prevBtnDisabled}
        edgeStyle={true}
        moreClasses={`${styles.navButton} ${styles.navLeft}`}
      />
      <ArrowButtonRight
        onClick={onNextButtonClick}
        disabled={nextBtnDisabled}
        edgeStyle={true}
        moreClasses={`${styles.navButton} ${styles.navRight}`}
      />
    </nav>;
};

export default PropertiesNavigationBanner;
