import { useEffect } from 'react';
import { debounce } from 'ts-debounce';

export function useSetScrollbarWidthVar() {
  useEffect(() => {
    const onResize = () => {
      const scrollBarWidth = window.innerWidth - document.body.clientWidth;

      // Catch any crazy values. Which can happen because of zoom behavior, or because some
      // older devices return a huge value for window.innerWidth before re-calculating.
      // Our setTimeout should cover this but account for an unexpected value just in case.
      if (scrollBarWidth >= 0 && scrollBarWidth <= 50) {
        document.body.style.setProperty('--pbsk-client-scrollbar-width', `${scrollBarWidth}px`);
      }
    };

    // eslint-disable-next-line no-unused-vars
    const debouncedOnResize = debounce((_ev)=> onResize(), 200);

    setTimeout(onResize, 1);
    window.addEventListener('resize', debouncedOnResize);

    return () => {
      window.removeEventListener('resize', debouncedOnResize);
    };
  }, []);
}
