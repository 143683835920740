import { useEffect } from 'react';

export function useLookInsidePlayTextColor() {
  /* Play button text color should always be white UNLESS the background is white, then it should be purple. Since the background is a themed CSS variable, we don't know what it is until runtime and have to compare then.
  Note: this custom hook was made so that we can do this logic once at the page-level and not on every single Look Inside card. */
  useEffect(() => {
    const uiHighlightColor = window.getComputedStyle(document.documentElement).getPropertyValue('--pbsk-theme-uiHighlightColor');

    if (uiHighlightColor === '#ffffff') {
      document.documentElement.style.setProperty('--pbsk-component-lookinside-play-textColor', 'var(--pbsk-color-purple)');
    }
  }, []);

  return null;
}
