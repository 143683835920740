import React from 'react';
import VideoCard from '../MediaList/VideoCard';
import { PbsKidsVideo } from '@/types/pbskids-graph';
import styles from './SingleVideoModule.module.scss';

interface Props {
  item: PbsKidsVideo,
  inMasthead?: boolean,
  listId?: string,
}

const SingleVideoModule: React.FC<Props> = ({
  item,
  inMasthead = false,
  listId,
}) => {
  return (
    <section className={ styles.singleVideoModule }>
      <ul
        data-ga-view-list-module={listId}
        aria-label='Featured video'>
        <li>
          <VideoCard
            item={ item }
            inMasthead={ inMasthead }
            cardStyle='singleVideo'
            sizes='(min-width: 600px) 600px, 100vw'
          />
        </li>
      </ul>
    </section>
  );
};

export default SingleVideoModule;
