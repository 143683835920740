import { ReactElement } from 'react';
import BodyContentCommonModule, { BodyContentCommonModuleType } from '@/components/modules/BodyContentCommonModule';
import BodyContentGameModule, { BodyContentGameModuleType } from '@/components/modules/BodyContentGameModule';
import BodyContentVideoModule, { BodyContentVideoModuleType } from '@/components/modules/BodyContentVideoModule';
import { ResolvedPageThemes } from '@/utils/theming-system/types';

export const defaultElement = <div style={{ display: 'none' }}></div>;
export const placeHolderElement = <div style={{ width: '100%' }}></div>;

export type BodyContentModuleType =
  BodyContentCommonModuleType |
  BodyContentGameModuleType |
  BodyContentVideoModuleType;

export default function BodyContentModule({ module, referringPage, themeData }: {
  module: BodyContentModuleType,
  referringPage?: string,
  themeData?: ResolvedPageThemes,
}): ReactElement {
  switch (module.__typename) {
    case 'BodyContentModulesLiveTv':
    case 'BodyContentModulesVideo':
    case 'BodyContentModulesVideoPlaylist':
    case 'BodyContentModulesVideoContentCards':
      return <BodyContentVideoModule
        module={ module }
        referringPage={ referringPage }
      />;

    case 'BodyContentModulesBodyCopy':
    case 'BodyContentModulesTextBlurb':
    case 'BodyContentModulesPropertiesNavigationBanner':
    case 'BodyContentModulesFreeformGraphicAndText':
      return <BodyContentCommonModule
        module={ module }
        themeData={ themeData }
      />;

    case 'BodyContentModulesGamesCollage':
    case 'BodyContentModulesGamesCharacterFeatureCollage':
    case 'BodyContentModulesGameSet':
      return <BodyContentGameModule
        module={ module }
        themeData={ themeData }
      />;

    default:
      return defaultElement;
  }
}
